.first-part-first-card-kontakt {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 2rem 0 0 0;
}

.icon1-kontakt {
  width: 25%;
  margin-bottom: 2rem;
}

.title-kontakt {
  font-family: Overpass, sans-serif;
  font-size: 1.5rem;
  margin-bottom: 1rem;
  padding: 0 2rem;
  font-weight: 700;
}

.sub-title-kontakt {
  font-family: Overpass, sans-serif;
  font-size: 1.4rem;
  margin-bottom: 1.5rem;
  font-weight: 700;
}

.content-kontakt {
  font-family: Poppins, sans-serif;
  line-height: 1.5rem;
  padding: 0 1.5rem;
}

.office-image-kontakt {
  width: 100vw;
  position: relative;
  bottom: -0.24rem;
  z-index: 4;
  box-shadow: 0 10px 8px rgb(0, 0, 0, 0.2);
}

.second-card-kontakt-cont {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 2rem 0;
  box-shadow: 0 10px 8px rgb(0, 0, 0, 0.2);
  z-index: 3;
}

.kontakt-form-icon {
  width: 20%;
  margin-bottom: 1.5rem;
}

.form-kontakt-cont {
  padding-top: 1.5rem;
}

.third-card-kontakt-cont {
  padding: 3.5rem 0 5rem 0;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  box-shadow: 0 10px 8px rgb(0, 0, 0, 0.2);
  z-index: 2;
}

.info-kontakt-cont {
  padding: 1.5rem 0;
}

.googlemap {
  position: relative;
  width: 85vw;
  height: 25vh;
  border: 2px solid var(--darkblue);
  border-radius: 0 20% 0 20% ;
}

.display-desktop {
  display: flex;
  flex-direction: column;
}

@media screen and (min-width: 768px) {
  .main-kontakt {
    margin: 6rem 5rem 0;
  }

  .first-card-kontakt-cont {
    display: grid;
    grid-template-columns: 1fr 1fr;
    position: relative;
  }

  .second-part-first-card-kontakt {
    order: 1;
  }

  .first-part-first-card-kontakt {
    position: relative;
    order: 2;
    padding: 0;
    box-shadow: 10px 10px 8px rgb(0, 0, 0, 0.2);
    z-index: 3;
  }

  .circle-bg {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    position: absolute;
    background-color: var(--darkblue);
    top: -50px;
    right: -50px;
    z-index: -1;
    box-shadow: 5px 5px 8px rgb(0, 0, 0, 0.2);
  }

  .circle-bg-second {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    position: absolute;
    background-color: var(--orange);
    bottom: -50px;
    left: -50px;
    z-index: -1;
    box-shadow: 5px 5px 8px rgb(0, 0, 0, 0.2);
  }

  .office-image-kontakt {
    width: 100%;
    height: 100%;
    box-shadow: 10px 10px 8px rgb(0, 0, 0, 0.2);
    bottom: 0;
  }

  .icon1-kontakt {
    width: 15%;
    margin-bottom: 4rem;
  }

  .title-kontakt {
    margin-bottom: 0.5rem;
    font-size: 2rem;
  }

  .sub-title-kontakt {
    margin-bottom: 4rem;
    font-size: 1.6rem;
  }

  .content-kontakt {
    font-size: 1.1rem;
    padding: 0 4rem;
    margin-bottom: 2rem;
  }

  .links-kontakt {
    display: block;
    width: 50%;
  }

  .phone-kontakt {
    font-size: 1.5rem;
  }

  .phone-kontakt:hover {
    color: var(--orange);
  }

  .email-kontakt {
    font-size: 1.5rem;
  }

  .email-kontakt:hover {
    color: var(--darkblue);
  }

  .display-desktop {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .second-card-kontakt-cont {
    width: 100%;
    box-shadow: 10px 10px 8px rgb(0, 0, 0, 0.2);
  }

  .third-card-kontakt-cont {
    width: 100%;
    box-shadow: 10px 10px 8px rgb(0, 0, 0, 0.2);
  }

  .googlemap {
    width: 35vw;
    height: 40vh;
  }

  .form-group {
    width: 35vw;
  }

  .kontakt-form-icon {
    width: 15%;
    margin-bottom: 4rem;
  }
}
