.top-part-forside {
  position: relative;
  background-image: url("../img/background-top-forside.png");
  width: 100%;
  height: 30vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
}

.top-part-forside::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 30vh;
  background-color: rgb(4, 32, 99, 0.5);
}

.forside-text-over-image-cont {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  text-align: center;
}

.title-forside {
  font-family: Overpass, sans-serif;
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
  padding: 0 2rem;
  font-weight: 700;
}

.content-forside {
  font-family: Poppins, sans-serif;
  font-weight: 400;
  font-size: 0.9rem;
  padding: 0 1.5rem;
  line-height: 1.5rem;
}

.sub-title-forside {
  font-weight: 500;
  margin-bottom: 1.5rem;
  margin-top: -1rem;
}

.image-anette-forside {
  width: 100%;
  margin: 2rem 0 0 0;
}

.margin-section {
  margin: 1rem 0;
}

#text-under-image-forside {
  font-size: 1.6rem;
  line-height: 2.5rem;
  font-weight: 700;
  margin-bottom: 0;
}

#text-up-carousel-forside{
  font-size: 1.25rem;
}

.card-forside-cont-boxshadow {
  position: relative;
  padding: 3rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  box-shadow: 0 10px 8px rgb(0, 0, 0, 0.2);
  z-index: 4;
}

.card-forside-cont {
  position: relative;
  padding: 2rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  z-index: 4;
}

.forside-cont-grey-background {
  padding: 2rem 0 0 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.forside-icon-1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 25%;
}

.forside-icon-2 {
  width: 25%;
  margin-bottom: 2rem;
}

.forside-icon-3 {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 25%;
  z-index: -1;
}

.white-background {
  background-color: var(--white);
  z-index: 3;
}

#z-index-2 {
  z-index: 2;
}

#z-index-1 {
  z-index: 1;
}

.links-phone {
  color: var(--orange);
}

.buttons-forside {
  width: 10rem;
  height: 2.5rem;
  border-radius: 20px;
  border: none;
  background: linear-gradient(to left, #ffd700, #ffa500);
  margin-top: 1.5rem;
}

.buttons-forside:hover{
  background: linear-gradient(to left, #fff, #0a38a7);
  transform: scale(1.05,1.05);
  transition: ease-out 0.5s;
}

.font-buttons-forside:hover{
  color: var(--orange);
}

.font-buttons-forside {
  color: var(--white);
  font-family: Poppins, sans-serif;
  padding: 10px 20px;
  font-weight: 500;
  font-size: 1.1rem;
  transition: ease 0.3s;
}

.review-slider-forside-cont {
  display: flex;
}

.contactform-cont-forside {
  margin: 2rem 0;
}

.cont-links-forside {
  margin: 2rem 0;
}

.phone-link-forside {
  color: var(--darkblue);
  font-family: Overpass, sans-serif;
  font-weight: 700;
  font-size: 1.2rem;
  margin-bottom: 1.5rem;
}

.email-link-forside {
  color: var(--orange);
  font-family: Overpass, sans-serif;
  font-weight: 700;
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
}

@media screen and (min-width: 768px) {

  .top-part-forside{
    height: 70vh;
  }

  .top-part-forside::before{
    height: 70vh;
  }

  .title-forside{
    font-size: 2.5rem;
    padding: 0;
  }

  .content-forside{
    font-size: 1.5rem;
    line-height: 2rem;
    padding: 0;
  }

  .forside-cont-grey-background{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-flow: dense;
    margin: 0 5%;
  }

  .text-over-img-forside{
    padding: 0 25%;
  }

  .grid-display-1{
    grid-column: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .grid-display-2{
    grid-column: 2;   
    display: flex;
    flex-direction: column;
    text-align: left;
  }

  .image-anette-forside{
    width: 70%;
    display: flex;
    justify-content: left;
    border-radius: 5px;
  }

  .other-content-forside{
    text-align: left;
    padding: 0;
  }

  .sub-title-forside{
    font-size: 2rem;
  }

  .card-forside-cont-boxshadow{
    margin: 0 5%;
    box-shadow: 10px 10px 8px rgb(0, 0, 0, 0.2);
  }

  .forside-icon-1{
    width: 10%;
  }

  .forside-icon-2{
    width: 8%;
  }

  .forside-icon-3{
    width: 10%;
  }

  .desktop-layout-forside{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 2rem;
    margin: 2rem 5% 0;
  }

  .icon-desktop-grid{
    width: 30%;
  }

  .card-desktop-grid{
    margin: 0;
  }

  .content-desktop-grid{
    padding: 0 5%;
  }

  #text-up-carousel-forside-cont{
    margin: 1rem 0;
  }

  #text-up-carousel-forside{
    font-size: 1.8rem;
  }

  #under-carousel-section-forside{
    margin-top: 5rem;
  }

  .cont-links-forside{
    display: flex;
    justify-content: space-between;
    width: 30%;
    max-width: 50%;
  }
}