:root {
  --darkblue: #042063;
  --white: #ffffff;
  --lightblue: #0a38a7;
  --lightgray: #F4F7FB;
  --orange: #fd7704;
  --black: black;
  --content-color: #0d2036;
}

.contact-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 60vw;
  margin: 0 auto;
}

.contact-form label {
  font-size: 1.2rem;
  margin-top: 1rem;
}

.contact-form input,
.contact-form textarea {
  padding: 1rem;
  margin-top: 0.5rem;
  font-size: 1.2rem;
}

.form-group {
  display: flex;
  margin: 0.5rem 0;
  width: 80vw;
}

.input-contact-form {
  font-family: Overpass, sans-serif;
  font-weight: 600;
  color: var(--orange);
  border: none;
  box-shadow: inset 4px 4px 10px rgba(0, 0, 0, 0.3);
  border-radius: 2px;
  width: 100%;
}

::placeholder{
    color: var(--black);
}

.contact-form input[type="submit"] {
  margin-top: 1rem;
  background: linear-gradient(to left, #FFD700, #FFA500);
  color: var(--white);
  font-size: 1.2rem;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  max-width: 400px;
  width: 200px;
}

.contact-form input[type="submit"]:hover {
  background: linear-gradient(to left, #fff, #0a38a7);
  transform: scale(1.05,1.05);
  transition: ease-out 0.5s;
  color: var(--orange);
}

.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--orange);
  color: var(--content-color);
  padding: 2rem;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.3);
  z-index: 999;
  font-family: Overpass, sans-serif;
  font-size: 1.5rem;
  max-width: 80vw;
  width: 80vw;
  display: none;
}

.popup.show {
  display: block;
  animation: fadeIn 0.5s ease;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media screen and (min-width: 768px) {
  .form-group{
    max-width: 50vw;
  }
}

