:root {
  --darkblue: #042063;
  --white: #ffffff;
  --lightblue: #034289;
  --orange: #ff9e29;
}

.footer-cont-total {
  color: var(--white);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 5vh;
  font-family: "Overpass", sans-serif;
  overflow: hidden;
}

.footer-cont-top {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: left;
}

.footer-cont-infos {
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 250px;
}

.footer-cont-bot {
  background-color: var(--darkblue);
  height: 5vh;
  font-weight: 500;
  font-size: smaller;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-shadow: 0 -10px 8px 0 rgba(0, 0, 0, 0.2);
  z-index: 20;
}

.footer-first-part {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.semi-circle-footer {
  width: 300px;
  height: 100px;
  border-radius: 50% 50% 0 0/ 100% 100% 0px 0px;
  background-color: var(--darkblue);
  z-index: 2;
}

.logo-footer {
  margin-top: 2rem;
  width: 75%;
  z-index: 2;
}

.text-logo-footer-cont {
  background-color: var(--darkblue);
  box-shadow: 0 10px 8px 0 rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.text-logo-footer {
  font-size: 1.2rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.content-cont-footer {
  background-color: var(--lightblue);
  width: auto;
  padding-left: 2rem;
}

.titles-footer {
  color: var(--orange);
  font-size: 1.2rem;
  margin: 1.5rem 1.5rem 1.5rem 0;
  font-weight: 600;
}

.links-footer {
  text-decoration: none;
  color: var(--white);
}

.links-footer:hover {
  color: var(--orange);
}

.text-footer {
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  line-height: 0.5rem;
}

.footer-some {
  margin-top: 1.2rem;
}

.some-desktop{
  display: none;
}

@media screen and (min-width: 768px) {
  .footer-first-part{
    width: 100%;
  }

  .footer-cont-infos {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    height: 400px;
    background-color: var(--lightblue);
  }

  .content-cont-footer {
    display:  flex;
    justify-content: center;
    flex-direction: column;
    text-align: left;
    margin-left: 15vw;
  }

  .logo-footer {
    margin-top: 2.5rem;
  }

  .text-logo-footer-cont{
    width: 100%;
  }

  .titles-footer {
    margin: 2rem 0;
    font-size: 2rem;
  }

  .text-footer{
    font-size: 1.5rem;
    line-height: 1.2rem;
  }

  .footer-some {
    margin-top: 1.1rem;
    display: flex;
  }

  .some-mobile{
    display: none;
  }

  .some-desktop{
    display: block
  }

  .semi-circle-footer{
    width: 350px;
  }

  .text-logo-footer-cont{
    padding: 2rem 0;
  }
}
