/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
a {
  text-decoration: none;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

:root {
  --darkblue: #042063;
  --white: #ffffff;
  --lightblue: #0a38a7;
  --orange: #fd7702;
}

/*END RESET CSS*/
/*NAV STYLING*/

header {
  background-color: var(--darkblue);
  color: var(--white);
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
}

.nav-area {
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 8vh;
  width: 100%;
  padding: 10px 0;
}

.logo-cont {
  display: none;
}

.logo-nav {
  height: 3.5rem;
  font-size: 10px;
  color: inherit;
  margin-left: 2rem;
  margin-right: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo {
  width: 80%;
}

.menus {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  list-style: none;
}

.menu-items {
  display: flex;
  align-items: center;
  position: relative;
  font-size: 14px;
  text-transform: uppercase;
  font-family: "Overpass", sans-serif;
  margin: 0 0.5rem;
}

.menu-items a {
  display: block;
  font-size: inherit;
  color: inherit;
  text-decoration: none;
}

.menu-items button {
  display: flex;
  align-items: center;
  color: inherit;
  font-size: inherit;
  font-family: "Overpass", sans-serif;
  border: none;
  background-color: transparent;
  text-transform: uppercase;
  cursor: pointer;
  width: 150%;
  height: auto;
  padding: 0;
}

button span {
  margin-left: 3px;
}

.menu-items > a,
.menu-items button {
  text-align: left;
}

.menu-items a:hover,
.menu-items button:hover {
  color: var(--orange);
  width: 100%;
}

.arrow {
  font-size: 1.2rem;
}

.dropdown {
  position: absolute;
  right: 0;
  left: 0;
  top: 3.5rem;
  box-shadow: 0 10px 15px -3px rgba(46, 41, 51, 0.08),
    0 4px 6px -2px rgba(71, 63, 79, 0.16);
  font-size: 0.875rem;
  z-index: 1;
  min-width: 15rem;
  padding: 0.5rem 0;
  list-style: none;
  background-color: var(--lightblue);
  border-radius: 0.5rem;
  display: none;
}

.dropdown.show {
  display: block;
}

.dropdown {
  position: absolute;
}

.dropdown-submenu {
  position: absolute;
  left: 103%;
  top: -6px;
}

.mobile-menu-icon {
  display: none;
}

@media screen and (max-width: 768px) {
  .menus {
    display: none;
  }

  .mobile-menu-icon {
    display: block;
    color: var(--white);
    display: flex;
    align-items: center;
    font-size: 4vh;
    cursor: pointer;
    top: 0;
    right: 0;
  }

  .dropdown .dropdown-submenu {
    position: absolute;
    left: 100%;
    top: -7px;
  }

  .nav-area {
    display: flex;
    align-items: center;
    height: 10vh;
    padding: 0;
  }

  .logo-cont {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 3rem;
  }

  .logo {
    width: 50%;
  }

  .logo-nav {
    display: none;
  }
}
