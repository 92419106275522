.review-container {
  z-index: 0;
  background-color: var(--orange);
}

.review-bloc {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 2rem 0;
  font-family: "Overpass", sans-serif;
  z-index: 2;
}

.review-logo-cont {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 1rem 0;
}

.logo-cont-display{
  display: flex;
  justify-content: center;
  align-items: center;
}

.review-company-name {
  text-align: center;
  font-size: 2rem;
  font-weight: 600;
  padding: 1.5rem 1rem;
  color: var(--white);
}

.review-content {
  text-align: left;
  font-family: Poppins, sans-serif;
  font-size: 1.2rem;
  padding: 1.5rem 2rem;
  line-height: 2rem;
  font-weight: 300;
  color: var(--darkblue);
}

.review-person-name {
  text-align: center;
  font-size: 1.8rem;
  font-weight: 600;
  padding: 1rem 1rem;
  color: var(--white);
}

.review-person-title {
  text-align: center;
  font-size: 1.5rem;
  padding: 0rem 1rem;
  font-weight: 500;
  color: var(--darkblue);
  margin-bottom: 2rem;
}

.review-logo {
  max-width: 80%;
}

.slick-dots {
  position: absolute !important;
  bottom: 0 !important;
  margin-bottom: 1rem !important;
}

.slick-dots li button::before {
  color: var(--white) !important;
  font-size: 12px !important;
}

.slick-dots li.slick-active button:before {
  color: var(--orange) !important;
}

.slick-prev,
.slick-next {
  display: none !important;
}

@media screen and (min-width: 768px) {
  /* REFERENCER CAROUSEL */

  .content {
    padding-top: 10vh;
  }

  .slick-prev,
  .slick-next {
    display: block !important;
  }

  .slick-prev:before, .slick-next:before {
    font-size: 40px;
  }

  .review-container .slick-prev,
  .review-container .slick-next {
    z-index: 1;
  }

  .review-container .slick-prev {
    left: 40px;
  }

  .review-container .slick-next {
    right: 40px;
  }

  .review-logo-cont{
    margin: 2rem 0 4rem;
  }

  .review-company-name{
    display: none;
  }

  .review-content{
    padding: 0 10%;
  }

  .review-person-name{
    padding: 3rem 1rem 1.5rem;
  }

  .slick-dots{
    padding: 2rem 0;
    margin: 0 !important;
  }

  #nrgi-background::before, #ase-background::before, #sander-gruppen-background::before, #adk-background::before, #koldogsort-background::before, #rhetorica-background::before, #siteguard-background::before, #imploy-background::before, #adagency-background::before, #tornvig-background::before{
    background-size: 15%;
  }
}
