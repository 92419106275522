:root {
  --darkblue: #042063;
  --white: #ffffff;
  --lightblue: #034289;
  --orange: #FF9E29;
  --content: #0D2036;
}

.sidebar-toggle-logo {
  font-size: 2rem;
  color: var(--white);
}

.sidebar-toggle {
  display: flex;
  justify-content: flex-end;
  margin-left: 2rem;
}

.sidebar {
  width: 75%;
  background-color: #F4F7FB;
  position: absolute;
  height: 87vh;
  top: 10vh;
  left: -100%;
  transition: 0.3s all ease-in-out;
  cursor: pointer;
  transition: 0.5s all ease-out;
  overflow: auto;
  z-index: 3;
  border-radius: 0 0 50px 0;
}

.sidebar.active {
  left: 0;
}

.menus-sidebar {
  justify-content: center;
  text-decoration: none;
  color: var(--content);
  font-size: 1.2rem;
  font-family: "Overpass", sans-serif;
  display: flex;
  align-items: left;
  flex-direction: column;
}

.sidebar-item {
  padding: 0.75em 1em;
  display: block;
  transition: background-color 0.15s;
  margin: 1rem 0;
  font-weight: 600;
  font-size: 1.25rem;
}

.sidebar-item:hover {
  border-left: 2px solid var(--orange);
}

.sidebar-title {
  display: flex;
  font-size: 1em;
  justify-content: space-between;
}

.sidebar-title span i {
  display: inline-block;
  width: 1.5em;
}

.sidebar-title .toggle-btn {
  transition: transform 0.3s;
}

.sidebar-item.open > .sidebar-title .toggle-btn {
  transform: rotate(180deg);
}

.sidebar-content {
  padding-top: 0.25em;
  height: 0;
  overflow: hidden;
}

.sidebar-item.open > .sidebar-content {
  height: auto;
}

.sidebar-item.plain {
  color: var(--content);
  text-decoration: none;
}

.sidebar-item.plain i {
  display: inline-block;
  width: 1.5em;
}
