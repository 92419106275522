/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
a {
  text-decoration: none;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

:root {
  --darkblue: #042063;
  --white: #ffffff;
  --lightblue: #0a38a7;
  --lightgray: #f4f7fb;
  --orange: #fd7704;
  --black: black;
  --content-color: #0d2036;
}

.content {
  padding-top: 90px;
}

.darkblue-font {
  color: var(--darkblue);
}

.very-darkblue-font {
  color: var(--content-color);
}

.orange-font {
  color: var(--orange);
}

.white-font {
  color: var(--white);
}

.lightgray-font{
  color: var(--lightgray);
}

.lightgray-bg {
  background-color: var(--lightgray);
}

.darkblue-bg {
  background-color: var(--darkblue);
}

.orange-bg {
  background-color: var(--orange);
}

/* General layouts and displays*/

.card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.title {
  font-family: Overpass, sans-serif;
  font-size: 1.5rem;
  font-weight: 500;
  z-index: 1;
  line-height: 1.6rem;
}

.subtitle {
  font-family: Overpass, sans-serif;
  font-size: 1.4rem;
  font-weight: 400;
}

.general-content {
  font-family: Poppins, sans-serif;
  line-height: 1.8rem;
  font-size: 0.9rem;
}

.titles-margin {
  margin: 1rem 0 2rem;
}

.margin-bottom-title {
  margin-bottom: 0.4rem;
}

.margin-top-content{
  margin-top: 1.5rem;
}

.bolding{
  font-weight: 800;
}

.lightbolding{
  font-weight: 700;
}

.card-with-bg-icon{
  position: relative;
}

.higher-dropshadow{
  z-index: 4;
}

.secondlevel-dropshadow{
  z-index: 3;
}

.thirdlevel-dropshadow{
  z-index: 2;
}

.lower-dropshadow{
  z-index: 1;
}

.line-height-content{
  line-height: 1.5rem;
}

@media screen and (min-width: 768px) {
  .top-content-margin{
    margin-top: 4rem!important;
  }

  .title {
    font-size: 2.2rem;
    line-height: 2.2rem;
  }

  .subtitle {
    font-size: 1.6rem;
  }

  .general-content{
    font-size: 1.2rem;
  }

  .titles-margin{
    margin: 2rem 0 3rem;
  }

  .layout-card-content{
    align-items: flex-start;
    text-align: left;
  }

  .outcard-margin{
    margin: 4rem 0;
  }

  .content-top-margin{
    margin: 0 25%
  }

  .content-margin {
    margin: 0 10%;
  }

  .line-height-content{
    line-height: 2rem;
  }
}

/* TEAMET PAGE MOBILE*/

.icon-team{
  width: 25%;
}

.picture-team{
  width: 80%;
  border-radius: 100%;
}

.main-salgsteam-data{
  margin: 0 5%
}

.picture-team-cont{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 3rem 0 2rem;
}

.content-team-cont{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

/* KARRIERE PAGE MOBILE*/

.title-karriere{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
}

.layout-karriere{
  margin: 0 1.5rem 2rem;
}

.title-list-karriere{
  font-family: Overpass, sans-serif;
  margin: 2rem 0 1rem;
  font-size: 1.2rem;
  color: var(--orange);
  font-weight: 700;
}

.cont-list-karriere{
  margin-left: 1.2rem;
}

/* MØDEBOOKING PAGE MOBILE */

.content-mødebooking-cont{
  margin: 4rem 5% 0;
}

.icon-booking{
  width: 25%;
}

.office-booking{
  width: 100%;
  display: block;
}

.anette-booking{
  width: 100%;
  display: block;
}

/* LEADGENERERING PAGE MOBILE*/

.card-lead {
  padding: 3rem 2rem;
  box-shadow: 0 10px 8px rgb(0, 0, 0, 0.2);
  position: relative;
}

#higher-depth {
  z-index: 4;
}

#lower-depth {
  z-index: 3;
}

.icon1-lead {
  width: 20%;
}

.sub-subtitle-lead {
  font-family: Poppins, sans-serif;
  color: var(--darkblue);
  font-size: 1.2rem;
  margin-bottom: 2rem;
  line-height: 1.5rem;
}

.img-lead {
  width: 100vw;
}

/* SEMINAR PAGE MOBILE*/

.seminar-icon{
  width: 20%;
}

.img-cont-seminar{
  position: relative;
}

.img-seminar{
  width: 100vw;
  box-shadow: 0 10px 8px rgb(0, 0, 0, 0.2);
}

/* MARKETINGSTRATEGI PAGE MOBILE */

.marketing-icon{
  width: 20%;
}

ol > li::marker {
  font-weight: bold;
}

.display-list-marketing{
  margin: 3rem 0 1rem;
  padding-left: 1rem;
  text-align: left; 
}

.text-marketing{
  margin: 3rem 2rem;
  text-align: center;
}

.icons-marketing{
  width: 20%;
}

.content-margin-marketing{
  margin: 3rem 0 0;
}

/* PRIVATLIVSPOLITIK PAGE MOBILE */

.main-layout-privatlivspolitik {
  margin: 2rem;
  font-family: "Overpass", sans-serif;
}

.main-title-politik {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 2.5rem;
  height: 30vh;
  background-color: var(--lightgray);
  color: var(--darkblue);
  font-weight: 600;
  margin: 0 -2rem;
}

.main-section-politik {
  margin: 2rem 0;
}

.second-title-politik {
  font-size: 1.5rem;
  color: var(--darkblue);
  font-weight: 600;
  margin: 2rem 0 1.5rem 0;
}

.sub-title-politik {
  font-size: 1.2rem;
  margin: 1.5rem 0 1rem 0;
  font-weight: 500;
}

.link-politik {
  text-decoration: none;
  color: var(--darkblue);
  font-weight: 500;
}

@media screen and (min-width: 768px) {
  /* PRIVATLIVSPOLITIK PAGE DESKTOP */

  .main-section-politik {
    margin: 0 15%;
    font-size: larger;
  }

  .second-title-politik {
    font-size: 2rem;
  }

  .sub-title-politik {
    font-size: 1.5rem;
  }

  .content-politik {
    font-weight: 300;
    line-height: 1.5rem;
  }

  .link-politik:hover {
    color: var(--orange);
  }

  /* TEAMET PAGE DESKTOP */

  .icon-team{
    width: 8%;
  }

  #display-card-team{
    margin: 0 5%;
  }

  .person-cont-team{
    display: grid;
    grid-template-columns: 1.5fr 3fr;
    margin: 3rem 0 0;
  }

  .content-team-cont{
    align-items: flex-start;
    text-align: left;
    margin-right: 20%;
  }

  .picture-team{
    width: 70%;
  }

  /* KARRIERE PAGE DESKTOP*/

  .main-karriere{
    margin: 0 5%;
  }

  .layout-karriere{
    margin: 0 25% 0 0;
  }

  .title-karriere{
    justify-content: left;
  }

  /* MØDEBOOKING PAGE DESKTOP */

  .icon-booking{
    width: 8%;
  }

  .desktop-layout-booking-cont{
    margin: 0 5%;
  }

  .desktop-layout-booking{
    margin: 0 25%;
  }

  .box-shadow-right{
    z-index: 10;
    box-shadow: 10px 0px 8px rgb(0, 0, 0, 0.4);
  }

  .box-shadow-left{
    z-index: 10;
    box-shadow: -10px 0px 8px rgb(0, 0, 0, 0.4);
    background-image: url("../img/anette-office.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  .card-booking-cont{
    display: grid;
    grid-template-columns: 1.5fr 2fr;
  }

  .content-mødebooking-cont{
    margin: 4rem 0 0;
  }

  .margin-cont-booking{
    margin: 4rem 5%;
  }

  .card-booking-cont-2{
    display: grid;
    grid-template-columns: 1.5fr 1.5fr;
    width: 90vw;
  }

  .anette-booking{
    display: none;
  }

  #grid-booking-1{
    order: 2;
  }

  #grid-booking-2{
    order: 1;
    padding-right: 15%;
  }

  #margin-booking{
    padding: 4rem 20%;
  }

  .desktop-icon-size{
    width: 15%!important;
  }

  /* LEADGENERERING PAGE DESKTOP*/

  .main-desktop-lead {
    margin: 0 4rem;
  }

  .icon1-lead {
    width: 8%;
  }

  .card-lead {
    padding: 5rem 2rem 5rem;
    box-shadow: 10px 10px 8px rgb(0, 0, 0, 0.2);
  }

  .desktop-layout-lead {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    padding: 5rem 0;
  }

  #lower-depth {
    margin: 0 1rem 0 0;
  }

  #layout-grid-lead {
    margin: 0 0 0 1rem;
  }

  .img-lead {
    width: 90%;
    box-shadow: 10px 10px 8px rgb(0, 0, 0, 0.2);
  }

  .lead-booker-img-cont {
    position: relative;
    margin: 0 1rem 0 0;
    z-index: 2;
  }

  .color-bg {
    position: absolute;
    background-color: rgb(253, 119, 4, 0.7);
    width: 90%;
    height: 100%;
    bottom: -10px;
    right: 50px;
    z-index: -1;
  }

  /* SEMINAR PAGE DESKTOP*/

  .seminar-icon{
    width: 8%;
  }

  .img-seminar{
    width: 100%;
  }

  .desktop-layout-seminar{
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 0 0 5rem 0;
    align-items: center;
  }

  .content-seminar-desktop{
    text-align: left;
    align-items: flex-start;
  }

  #desktop-display-content-seminar{
    text-align: left;
    font-size: 1.1rem;
  }

  /* MARKETINGSTRATEGI PAGE DESKTOP */

  .marketing-icon, .icons-marketing{
    width: 8%;
  }

  .display-list-marketing{
    display: grid;
    justify-items: center;
    grid-template-columns: 1fr 1fr;
  }

  .padding-card-marketing{
    padding: 5rem 15%;
  }

  .list-marketing-desktop{
    width: 90%;
  }

  .list-marketing-desktop-right{
    align-items: center;
  }

  .line-height-marketing{
    line-height: 2rem;
  }
  /* VORES CASES PAGE DESKTOP*/

  .cases-desktop-layout{
    padding: 7rem 0;
    font-size: 1.3rem;
  }

  #title-cases{
    font-size: 3rem;
  }

}
